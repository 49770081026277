import React, { useContext } from "react"
import Layout from "../components/Layout"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import { Container, Col, Row } from "react-bootstrap"
import styled from "styled-components"
import { myContext } from "../ThemeContext"
import { Banner } from "../components/Banner"
import BackgroundImage from "gatsby-background-image"
import SEO from "../components/seo"

export default function Index() {
  const data = useStaticQuery(graphql`
    query indexPage {
      FeatureImage: file(relativePath: { eq: "homepage/introduction.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            aspectRatio
            base64
            sizes
            src
            srcSet
          }
        }
      }
      BackgroundImage: file(relativePath: { eq: "homepage/background.jpeg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      BannerImage: file(
        relativePath: { eq: "banner/AdobeStock_297400808.jpeg" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 400, maxHeight: 250) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const AboutSection = styled.div`
    margin: 4rem 0 4rem 0;
    position: relative;
    .description {
      font-weight: 400;
      font-size: 14px;
      line-height: 26px;
      margin-bottom: 15px;
      margin-top: 1rem;
    }
  `

  return (
    <Layout headerType="index">
      <SEO />
      <Introduction image={data.BackgroundImage} />
      <Container>
        <AboutSection>
          <Row>
            <Col lg="6">
              <Row>
                <Col>
                  <div className="description">
                    <p>
                      Here at Compulease™ we specialise in providing high
                      quality computer leasing and computer finance solutions to
                      all areas of business and education, allowing you to have
                      the most up-to-date technology in your offices and school
                      classrooms.
                    </p>
                    <p>
                      You can be confident that our unique and flexible approach
                      to your computer leasing requirements have already helped
                      many schools and businesses get the most out of their
                      computer finance budgets. We are here to make your
                      decisions a lot simpler by reducing your cost, improving
                      your cash-flow and guaranteeing that your computers will
                      not be left behind as technology develops.
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg="6">
              <div className="position-relative">
                <Img
                  fluid={data.FeatureImage.childImageSharp.fluid}
                  className="ttm-bg-layer-inner"
                  alt="null"
                />
              </div>
            </Col>
          </Row>
        </AboutSection>
        <Banner
          image={data.BannerImage.childImageSharp.fluid}
          title="Speak to our friendly customer service team for all your Education and Business computer leasing and finance needs"
          link={{
            text: "Get in touch",
            to: "/ContactUs/",
          }}
        />
        <ColumnSection />
      </Container>
    </Layout>
  )
}

const IntroductionSection = styled.section`
  .background-overlay {
    background-color: rgba(14, 43, 137, 0.4);
    padding: 4rem 0;
  }
  .introduction-title {
    padding-bottom: 1rem;
    margin-bottom: 2rem;
    h1,
    h4 {
      color: #fff;
    }
  }
  @media (min-width: 768px) {
    .introduction-title {
      padding-bottom: 4rem;
    }
  }
  @media (max-width: 768px) {
    .sector-cta-boxes {
      /* align boxes in center */
      display: flex;
      justify-content: center;
    }
  }
  @media (max-width: 576px) {
    .sector-cta-boxes {
      margin-bottom: 1rem;
      a {
        width: 100%;
      }
    }
  }
  .image {
    padding-top: 2rem;
  }
`

const CTABox = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 1rem;
  max-width: 310px;
  height: 100%;
  text-align: center;
  &:hover {
    transition: all 0.4s ease-in-out;
    background-color: rgba(255, 255, 255, 0.85);
  }
  h3 {
    font-weight: 500;
    color: ${props => props.theme.primaryBlue};
  }
  p {
    flex-grow: 2;
    color: #263045;
  }
  span {
    background-color: ${props => props.theme.darkGrey};
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    padding: 0.4rem 1rem;
    border-radius: 50px;
  }
  @media (max-width: 576px) {
    max-width: none;
    background-color: ${props =>
      props.rhs ? props.theme.primaryBlue : "#fff"};
    padding: 1rem;
    border-radius: 50px;
    width: 100%;
    p,
    a,
    span {
      display: none;
    }
    h3 {
      margin-bottom: 0;
      color: ${props => (props.rhs ? "#fff" : props.theme.primaryBlue)};
    }
    &:hover {
      background-color: ${props => props.theme.secondaryBlue};
    }
  }
  @media (min-width: 992px) {
    float: ${props => (props.rhs ? "" : "right")};
  }
`

function Introduction({ image }) {
  const theme = useContext(myContext)

  return (
    <IntroductionSection theme={theme}>
      <BackgroundImage fluid={image.childImageSharp.fluid}>
        <div className="background-overlay">
          <Container>
            <Row>
              <Col lg="8">
                <div className="introduction-title">
                  <h1>
                    The UK’s No.1 Computer Leasing Company for Schools and
                    Business
                  </h1>
                  <h4>Computer Leasing and Computer Finance Solutions</h4>
                </div>
              </Col>
            </Row>
            <Row>
              <Col
                xs="12"
                md="6"
                lg={{ span: "4", offset: "4" }}
                className="sector-cta-boxes"
              >
                <Link to="/education/Home/">
                  <CTABox theme={theme}>
                    <h3>Compulease™ Education</h3>
                    <p>
                      Tailored equipment and leasing solutions for schools,
                      colleges and universities
                    </p>
                    <span href="education/home">Learn more</span>
                  </CTABox>
                </Link>
              </Col>
              <Col
                xs="12"
                md="6"
                lg={{ span: "4" }}
                className="sector-cta-boxes"
              >
                <Link to="/business/Home/">
                  <CTABox rhs theme={theme}>
                    <h3>Compulease™ Business</h3>
                    <p>
                      Tax efficient flexible leasing solutions for your business
                    </p>
                    <span href="business/Home">Learn more</span>
                  </CTABox>
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
      </BackgroundImage>
    </IntroductionSection>
  )
}

function ColumnSection() {
  const Section = styled.section`
    margin: 4rem 0;
    .content {
    }
    .title {
      color: #0e2b89;
    }
    .description {
    }
  `

  const FeaturedIconBox = ({ title, description }) => (
    <Col md="4">
      <div className="box">
        <div className="content">
          <div className="title">
            <h4>{title}</h4>
          </div>
          <div className="description">
            <p>{description}</p>
          </div>
        </div>
      </div>
    </Col>
  )

  return (
    <Section>
      <Row>
        <FeaturedIconBox
          title="Our Aim"
          description="Our aim is to meet our customer’s business or education needs with the largest range of IT products and services. If you have a specific requirement and you can’t find it in our range, feel free to call one of our customer support team and we’ll source it for you, so you will still receive the benefit of our purchasing power."
        />
        <FeaturedIconBox
          title="Up to date Technology"
          description="Your Compulease™ lease agreement automatically qualifies you for new equipment every three years, in most cases, with no increase to your original rental amount. At the time of upgrade, you may also be able to keep your original equipment, absolutely FREE of charge."
        />
        <FeaturedIconBox
          title="Maximise your Investment"
          description="Compulease™ is all about maximising the performance of your investment and we are committed to providing clients unrivalled and impartial advice on a complete range of goods and services and to developing long term business relationships."
        />
      </Row>
    </Section>
  )
}
